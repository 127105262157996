<template>
    <div class="manuals-wrapper">
        <section class="container manuals-container">
            <h2 class="section__title">{{ $t('Download manual') }}</h2>

            <p class="section__text">
                {{ $t('Select the device') }}
                <br>
                {{ $t('and the language of the manual') }}
            </p>

            <div class="manuals-form">
                <Select
                    titleAttrName="title"
                    valueAttrName="value"
                    v-model="selectedDevice"
                    :options="devices"
                />

                <Select
                    style="margin-top: 16px;"
                    titleAttrName="title"
                    valueAttrName="folder"
                    v-model="selectedLanguage"
                    :options="languages"
                />
            </div>

            <div class="manuals-cards">
                <template
                    v-for="manual in DexnodeManualsMap[selectedLanguage]"
                >
                    <div
                        class="manuals-cards__card"
                        :key="manual.title"
                    >
                        <p class="manuals-cards__card-date">{{ manual.date }}</p>
                        <p class="manuals-cards__card-title">
                            {{ manual.title }}
                        </p>

                        <div class="manuals-cards__card-footer">
                            <div class="manuals-cards__card-name">
                                {{ manual.file }}
                            </div>

                            <router-link
                                tag="button"
                                class="button button--transparent"
                                :to="`/pdf/?url=/docs/${selectedLanguage}/${manual.file}`"
                                taget="_blank"
                            >
                                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.46967 13.0303C0.176777 12.7374 0.176777 12.2626 0.46967 11.9697L5.43934 7L0.46967 2.03033C0.176777 1.73744 0.176777 1.26256 0.46967 0.96967C0.762563 0.676777 1.23744 0.676777 1.53033 0.96967L7.56066 7L1.53033 13.0303C1.23744 13.3232 0.762563 13.3232 0.46967 13.0303Z" fill="#DAF1F0"/>
                                </svg>
                            </router-link>
                        </div>
                    </div>
                </template>
            </div>
        </section>
    </div>
</template>
<script>
import Select from '@/components/UI/Select.vue'

const DexnodeManualsMap = {
    ENG: [
        // {
        //     title: 'DexNode User Manual',
        //     file: 'DexNode User Manual.pdf',
        //     date: '27 april 2024'
        // },
        {
            title: 'DexNone Instruction',
            // file: 'DexNode Instruction.pdf',
            file: 'DexNone Instruction.pdf',
            date: '27 april 2024'
        },
    ],
    ESP: [
        {
            title: 'Instrucción Dexnode',
            file: 'Instrucción Dexnode.pdf',
            date: '27 april 2024'
        },
        // {
        //     title: 'Manual de usuario del DexNode',
        //     file: 'Manual de usuario del DexNode.pdf',
        //     date: '27 april 2024'
        // },
    ],
    FRA: [
        {
            title: 'Instruction DexNode',
            file: 'Instruction DexNode.pdf',
            date: '27 april 2024'
        },
        // {
        //     title: 'Manuel d_utilisateurdu DexNode',
        //     file: 'Manuel d_utilisateurdu DexNode.pdf',
        //     date: '27 april 2024'
        // },
    ],
    DEU: [
        // {
        //     title: 'DexNode Benutzerhandbuch',
        //     file: 'DexNode Benutzerhandbuch.pdf',
        //     date: '27 april 2024'
        // },
        {
            title: 'Dexnode-Anweisung',
            file: 'Dexnode-Anweisung.pdf',
            date: '27 april 2024'
        },
    ],
    ITA: [
        {
            title: 'Istruzioni Dexnode',
            file: 'Istruzioni Dexnode.pdf',
            date: '27 april 2024'
        },
        // {
        //     title: 'Manuale Utente del DexNode',
        //     file: 'Manuale Utente del DexNode.pdf',
        //     date: '27 april 2024'
        // },
    ],
    ROM: [
        {
            title: 'Instrucțiuni Dexnode',
            file: 'Instrucțiuni Dexnode.pdf',
            date: '27 april 2024'
        },
        // {
        //     title: 'Manual de utilizare pentru DexNode',
        //     file: 'Manual de utilizare pentru DexNode.pdf',
        //     date: '27 april 2024'
        // },
    ],
    RUS: [
        // {
        //     title: 'Инструкция пользователя DexNode',
        //     file: 'Инструкция пользователя DexNode.pdf',
        //     date: '27 april 2024'
        // },
        {
            title: 'Инструкция DexNode',
            file: 'Инструкция  DexNode.pdf', // костыль
            date: '27 april 2024'
        },
    ],
    HUN: [
        // {
        //     title: 'DexNode Felhasználói Kézikönyv',
        //     file: 'DexNode Felhasználói Kézikönyv.pdf',
        //     date: '27 april 2024'
        // },
        {
            title: 'Dexnode Utasítás',
            file: 'Dexnode Utasítás.pdf',
            date: '27 april 2024'
        },
    ],
    POL: [
        {
            title: 'Instrukcja Dexnode',
            file: 'Instrukcja Dexnode.pdf',
            date: '27 april 2024'
        },
        // {
        //     title: 'Instrukcja obsługi DexNode',
        //     file: 'Instrukcja obsługi DexNode.pdf',
        //     date: '27 april 2024'
        // },
    ],
    AZE: [
        // {
        //     title: 'DexNode İstifadəçi Təlimatı',
        //     file: 'DexNode İstifadəçi Təlimatı.pdf',
        //     date: '27 april 2024'
        // },
        {
            title: 'Dexnode Təlimatı',
            file: 'Dexnode Təlimatı.pdf',
            date: '27 april 2024'
        },
    ],
    EST: [
        // {
        //     title: 'DexNode kasutaja juhised',
        //     file: 'DexNode kasutaja juhised.pdf',
        //     date: '27 april 2024'
        // },
        {
            title: 'Juhend Dexnode',
            file: 'Juhend Dexnode.pdf',
            date: '27 april 2024'
        },
    ],
    CZE: [
        // {
        //     title: 'DexNode Uživatelská Příručka',
        //     file: 'DexNode Uživatelská Příručka.pdf',
        //     date: '27 april 2024'
        // },
        {
            title: 'Instrukce Dexnode',
            file: 'Instrukce Dexnode.pdf',
            date: '27 april 2024'
        },
    ],
    BEL: [
        {
            title: 'Інструкцыя Dexnode',
            file: 'Інструкцыя Dexnode.pdf',
            date: '27 april 2024'
        },
        // {
        //     title: 'Кіраўніцтва па выкарыстанні DexNode',
        //     file: 'Кіраўніцтва па выкарыстанні DexNode.pdf',
        //     date: '27 april 2024'
        // },
    ],
    UKR: [
        // {
        //     title: 'Інструкція користувача DexNode',
        //     file: 'Інструкція користувача DexNode.pdf',
        //     date: '27 april 2024'
        // },
        {
            title: 'Інструкція Dexnode',
            file: 'Інструкція Dexnode.pdf',
            date: '27 april 2024'
        },
    ],
    KAZ: [
        {
            title: 'Dexnode Нұсқаулығы',
            file: 'Dexnode Нұсқаулығы.pdf',
            date: '27 april 2024'
        },
        // {
        //     title: 'DexNode Пайдаланушы нұсқаулығы',
        //     file: 'DexNode Пайдаланушы нұсқаулығы.pdf',
        //     date: '27 april 2024'
        // },
    ],
    LIT: [
        // {
        //     title: 'DexNode  vartotojo instrukcija',
        //     file: 'DexNode  vartotojo instrukcija.pdf',
        //     date: '27 april 2024'
        // },
        {
            title: 'Instrukcijos Dexnode',
            file: 'Instrukcijos Dexnode.pdf',
            date: '27 april 2024'
        },
    ]
}

export default {
    components: {
        Select
    },
    data() {
        return {
            DexnodeManualsMap,

            isSelectOpen: false,
            selectedLanguage: null,
            selectedDevice: null,
            languages: [
                {
                    title: 'English',
                    folder: 'ENG'
                },
                {
                    title: 'Spanish',
                    folder: 'ESP'
                },
                {
                    title: 'French',
                    folder: 'FRA'
                },
                {
                    title: 'German',
                    folder: 'DEU'
                },
                {
                    title: 'Italian',
                    folder: 'ITA'
                },
                {
                    title: 'Romanian',
                    folder: 'ROM'
                },
                {
                    title: 'Russian',
                    folder: 'RUS'
                },
                {
                    title: 'Hungarian',
                    folder: 'HUN'
                },
                {
                    title: 'Polish',
                    folder: 'POL'
                },
                {
                    title: 'Azerbaijani',
                    folder: 'AZE'
                },
                {
                    title: 'Estonian',
                    folder: 'EST'
                },
                {
                    title: 'Czech',
                    folder: 'CZE'
                },
                {
                    title: 'Belarusian',
                    folder: 'BEL'
                },
                {
                    title: 'Ukrainian',
                    folder: 'UKR'
                },
                {
                    title: 'Kazakh',
                    folder: 'KAZ'
                },
                {
                    title: 'Lithuanian',
                    folder: 'LIT'
                }
            ],
            devices: [
                {
                    title: 'Dexnode',
                    value: 'dexnode'
                }
            ]
        }
    },
    methods: {
    },
    created() {
        this.selectedLanguage = this.languages[0].folder
        this.selectedDevice = this.devices[0].value
    }
}
</script>
<style>
.manuals-wrapper {
    background:
        radial-gradient(60% 50% at 50% 60%, rgba(0, 81, 73, 0.40) 0%, rgba(25, 38, 104, 0.00) 100%)
    ;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}
.manuals-container {
    padding-top: 140px;
    padding-bottom: 140px;

    text-align: center;
}

.manuals-container .section__text {
    margin-top: 60px;
}

.manuals-form {
    max-width: 285px;
    margin-top: 32px;
    margin-left: auto;
    margin-right: auto;
}

.manuals-form__buttons {
    display: flex;
    align-items: center;
    gap: 24px;
}

.manuals-form .languages-list__current {
    width: 100%;
    justify-content: space-between;
}

.manuals-form__buttons .button {
    width: 100%;
    max-width: calc(50% - 12px);

    padding: 12px 26px;
    margin-top: 32px;
}
.manuals-container .button.button--transparent {
    background: transparent !important;
    color: #fff;
    border: 1px solid #02E1C7 !important;
}

.manuals-cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 30px;

    margin-top: 60px;
}

.manuals-cards__card {
    width: 100%;
    max-width: calc(50% - 15px);
    padding: 32px;
    border-radius: 16px;
    background: linear-gradient(90deg, rgba(126,206,202,0.08029149159663862) 0%, rgba(126,206,202,0.06) 100%);
    text-align: left;
}

.manuals-cards__card .button {
    padding: 12px 28px;
}

.manuals-cards__card-date {
    font-size: 18px;
    color: #00D5C8;
}

.manuals-cards__card-title {
    font-size: 24px;
    color: #fff;
    font-weight: 600;
}

.manuals-cards__card-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;
}

.manuals-cards__card-name {
    color: rgba(162, 220, 217, 0.6);
    color: #A2DCD9;
    opacity: 60%;
    font-size: 18px;
}

@media (max-width: 834px) {
    .manuals-cards__card {
        padding: 24px;
    }

    .manuals-cards__card-date,
    .manuals-cards__card-name {
        font-size: 16px;
    }

    .manuals-cards__card-title {
        font-size: 20px;
    }

    .manuals-cards__card-footer {
        margin-top: 24px;
    }

    .manuals-container {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}

@media (max-width: 600px) {
    .manuals-cards__card {
        max-width: 100%;
        padding: 16px;
    }

    .manuals-cards__card-footer {
        margin-top: 16px;
    }

    .manuals-container {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}
</style>